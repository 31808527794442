import * as $ from 'jquery'
import popper from '@popperjs/core'
import bootstrap from 'bootstrap'
import { Tooltip, Toast, Popover } from 'bootstrap'
//import 'jquery-ui/ui/core'
//import 'jquery.easing'
import 'owl.carousel'
require("select2/dist/js/select2.full.min.js")
require("jquery.nicescroll/dist/jquery.nicescroll.min.js")

jQuery.fn.ForceNumericOnly = function () {
	return this.each(function () {
		$(this).keydown(function (e) {
			var key = e.charCode || e.keyCode || 0;
			// allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
			// home, end, period, and numpad decimal
			return (
				key == 8 ||
				key == 9 ||
				key == 13 ||
				key == 46 ||
				key == 110 ||
				key == 190 ||
				(key >= 35 && key <= 40) ||
				(key >= 48 && key <= 57) ||
				(key >= 96 && key <= 105));
		});
	});
};

(function ($) {

	var $nWindowWidth = $(window).width(),
		$nWindowHeight = $(window).height(),
		$nClientWidth = Math.ceil($("body").prop("clientWidth")),
		$nClientHeight = Math.ceil($("body").prop("clientHeight")),
		$arResizedBlocks = [],
		$nBaseRisizeWidth = 1440,
		$owlCarouselPrizesListSlider,
		$owlCarouselPrizesListSliderConfig = {
			loop: true,
			margin: 0,
			stagePadding: 0,
			autoWidth: true,
			dots: false,
			nav: true,
			navText: ["<div class='owl__nav-left'></div>", "<div class='owl__nav-right'></div>"],
			items: 8,
			rewind: false,
			responsive: {
				/*0: {
					items: 1,
					margin: 15
				},
				576: {
					items: 1,
					margin: 15
				},
				768: {
					margin: 30
				},
				1440: {
					items: 10
				}*/
			}
		},
		nScrollTop = 0

	$(document).ready(function () {

		$('.--input-numbers').ForceNumericOnly()

		$('.hamburger').on('click', function (event) {
			event.preventDefault();
			$(this).toggleClass('is-active')
			$('body').toggleClass('--nav-show')
		});

		if ($('#home-clock-timer'))
			setInterval(function () { initClockTimer(); }, 1000);

		if ($(".owl-carousel.--prizes-list-slider").length > 0) {
			$owlCarouselPrizesListSlider = $(".owl-carousel.--prizes-list-slider").owlCarousel($owlCarouselPrizesListSliderConfig)
			$('.home__prizes-nav .prizes-nav__prev').on('click', function (event) { $owlCarouselPrizesListSlider.trigger('prev.owl.carousel') })
			$('.home__prizes-nav .prizes-nav__next').on('click', function (event) { $owlCarouselPrizesListSlider.trigger('next.owl.carousel') })
		}



		if ($("#winners-filter-form").length) {

			$(".filter-form__select").select2({
				minimumResultsForSearch: -1,
				width: '100%',
				placeholder: function () {
					$(this).data('placeholder')
				}
			}).on('select2:open', function (e) {
				$(".select2-container--default .select2-results>.select2-results__options").niceScroll({
					cursorcolor: "#21A038",
					cursoropacitymin: 1,
					cursorborder: "1px solid #21A038",
					cursorborderradius: "4px"
				})
			})

			/*$('#filter-btn').on('click', function(event) {

				event.preventDefault();

				var thisForm = $(this).closest('form'),
					formData = thisForm.serialize()

				$.ajax({
					url: 'https://sbermarket.andreybazykin.com/',
					type: 'POST',
					data: formData,
					beforeSend: function() {
						thisForm.addClass('--loading')
						$('#winners-table-body').find('.table-body__row').remove()
						$('#winners-table-body').removeClass('--no-data')
						$('#winners-table-body').addClass('--loading')
						$('#winners-download-more-btn').addClass('d-none')
						$('#winners-download-more-btn').attr('data-page', 1)
						$('#winners-download-more-btn').attr('data-week', $(".filter-form__select").val())
					}
				})
				.done(function(data) {

					var response = $.parseJSON(data);

					if(response.data.length) {

						if(response.total_pages > 1)
							$('#winners-download-more-btn').removeClass('d-none')

						for(var i in response.data) {

							$('#winners-table-body').append(templateWinnersTable({
								date: response.data[i][0],
								number: response.data[i][1],
								name: response.data[i][2],
								prize: response.data[i][3]
							}))

						}

					}
					else {
						$('#winners-download-more-btn').addClass('d-none')
						$('#winners-table-body').addClass('--no-data')
					}

				})
				.fail(function() {
				})
				.always(function() {
					thisForm.removeClass('--loading')
					$('#winners-table-body').removeClass('--loading')
				})
				
			})*/

			/*$('#winners-download-more-btn').on('click', function(event) {

				event.preventDefault();

				if($('#winners-table-body').hasClass('--loading'))
					return false;

				var thisBtn = $(this),
					nCurrentPage = parseInt(thisBtn.attr('data-page')),
					nNextPage = nCurrentPage + 1,
					sCurrentWeek = thisBtn.attr('data-week')

				$.ajax({
					url: 'https://sbermarket.andreybazykin.com/',
					type: 'POST',
					data: {
						filter_week: sCurrentWeek,
						page: nNextPage
					},
					beforeSend: function() {
						$('#winners-table-body').addClass('--loading')
					}
				})
				.done(function(data) {

					var response = $.parseJSON(data);

					if(response.data.length) {

						for(var i in response.data) {

							$('#winners-table-body').append(templateWinnersTable({
								date: response.data[i][0],
								number: response.data[i][1],
								name: response.data[i][2],
								prize: response.data[i][3]
							}))

						}

						if(nNextPage >= response.total_pages )
							$('#winners-download-more-btn').addClass('d-none')
						else
							thisBtn.attr('data-page', nNextPage)

					}
					else
						thisBtn.addClass('d-none')


				})
				.fail(function() {
				})
				.always(function() {
					$('#winners-table-body').removeClass('--loading')
				})
				
			})*/

		}

	})

	$(window).on('load', function () {

		$nWindowWidth = $(window).width()
		$nWindowHeight = $(window).height()
		$nClientWidth = Math.ceil($("body").prop("clientWidth"))
		$nClientHeight = Math.ceil($("body").prop("clientHeight"))

		//initResizeBlocks()
		fixedHeader()
		homePictureParallax()

	})

	$(window).on('resize', function (event) {

		$nWindowWidth = $(window).width()
		$nWindowHeight = $(window).height()
		$nClientWidth = Math.ceil($("body").prop("clientWidth"))
		$nClientHeight = Math.ceil($("body").prop("clientHeight"))

		homePictureParallax()


	})

	$(window).on('scroll', function (event) {

		nScrollTop = $(document).scrollTop()

		fixedHeader()

		if ($('.home__sticky').length > 0)
			homePictureParallax()



	})

	function fixedHeader() {

		nScrollTop = $(document).scrollTop()

		if (nScrollTop > 0)
			$('#header').addClass('--fixed')
		else
			$('#header').removeClass('--fixed')


	}

	function homePictureParallax() {

		if ($('.home__sticky').length > 0) {

			var blockHeight = parseFloat($('.home__sticky').closest('.home__right').height()),
				stickyHeight = parseFloat($('.home__sticky').height()),
				pictureClipWidth = parseFloat($('.home__sticky .home__present-block .present-block__picture').width()),
				pictureWidth = parseFloat($('.home__sticky .home__present-block .present-block__picture img').width()),
				moveHorizontalMax = pictureWidth - pictureClipWidth,
				maxScrollTop = blockHeight - stickyHeight,
				nMoveRatio = maxScrollTop / moveHorizontalMax

			nScrollTop = $(document).scrollTop()

			if (nScrollTop <= maxScrollTop)
				$('.home__sticky .home__present-block .present-block__picture  img').css('left', -(nScrollTop / nMoveRatio))

		}

	}

	function initClockTimer() {

		var endTime = new Date("6 November 2022 23:59:59 GMT+03:00");
		endTime = (Date.parse(endTime) / 1000);

		var now = new Date();
		now = (Date.parse(now) / 1000);

		var timeLeft = endTime - now;

		var days = Math.floor(timeLeft / 86400);
		var hours = Math.floor((timeLeft - (days * 86400)) / 3600);
		var minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
		var seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));

		if (hours < "10") { hours = "0" + hours; }
		if (minutes < "10") { minutes = "0" + minutes; }
		if (seconds < "10") { seconds = "0" + seconds; }

		if (days < 0) {
			days = "0"
			hours = minutes = seconds = "00"
		}

		$("#home-clock-timer-num-days").html(days);
		$("#home-clock-timer-text-days").html(declOfNum(days, ['день', 'дня', 'дней']));

		$("#home-clock-timer-num-hours").html(hours);
		$("#home-clock-timer-text-hours").html(declOfNum(hours, ['час', 'часа', 'часов']));

		$("#home-clock-timer-num-minutes").html(minutes);
		$("#home-clock-timer-text-minutes").html("мин");

		$("#home-clock-timer-num-seconds").html(seconds);
		$("#home-clock-timer-text-seconds").html("сек");

	}

	function declOfNum(number, titles) {
		let cases = [2, 0, 1, 1, 1, 2]
		return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
	}

	/*function initResizeBlocks() {

		$('[data-base-w]').each(function (index, element) {

			console.log($(element).css('border-radius'))

			var tmpBlock = {
				element: $(element),
				baseElementSizes: {
					width: parseFloat($(element).data('base-w')),
					height: parseFloat($(element).data('base-h')),
					pt: parseFloat($(element).css('padding').split(' ')[0]) ? parseFloat($(element).css('padding').split(' ')[0]) : 0,
					pe: parseFloat($(element).css('padding').split(' ')[1]) ? parseFloat($(element).css('padding').split(' ')[1]) : 0,
					pb: parseFloat($(element).css('padding').split(' ')[2]) ? parseFloat($(element).css('padding').split(' ')[2]) : 0,
					ps: parseFloat($(element).css('padding').split(' ')[3]) ? parseFloat($(element).css('padding').split(' ')[3]) : 0,
					widthClear: 0,
					heightClear: 0
				},
				ratioHeight: 1,
				currentClearWidth: parseFloat($(element).width()),
				calculateSizes: {
					height: 0
				}
			}

			tmpBlock.baseElementSizes.widthClear = tmpBlock.baseElementSizes.width - (tmpBlock.baseElementSizes.ps + tmpBlock.baseElementSizes.pe)
			tmpBlock.baseElementSizes.heightClear = tmpBlock.baseElementSizes.height - (tmpBlock.baseElementSizes.pt + tmpBlock.baseElementSizes.pb)
			tmpBlock.ratioHeight = tmpBlock.baseElementSizes.widthClear / tmpBlock.baseElementSizes.heightClear
			// tmpBlock.calculateSizes.height = tmpBlock.currentClearWidth / tmpBlock.ratioHeight

			$arResizedBlocks.push(tmpBlock)
		})

		// console.log($arResizedBlocks)
		recalculateBlocks()

	}

	function recalculateBlocks() {

		for (var i in $arResizedBlocks) {
			$arResizedBlocks[i].currentClearWidth = parseFloat($arResizedBlocks[i].element.width());
			$arResizedBlocks[i].calculateSizes.height = $arResizedBlocks[i].currentClearWidth / $arResizedBlocks[i].ratioHeight
		}

		//resizeBaseBlocks()

	}*/

	/*function resizeBaseBlocks() {

		for (var i in $arResizedBlocks) {

			$arResizedBlocks[i].element.height($arResizedBlocks[i].calculateSizes.height)

		}

		$('[data-base-w]').each(function (index, element) {

			var curPaddingX = parseFloat($(element).outerWidth() - $(element).width()),
				currPaddingY = parseFloat($(element).outerHeight() - $(element).height()),
				nBlockRatio = parseFloat($(element).data('base-w') - curPaddingX) / (parseFloat($(element).data('base-h')) - currPaddingY)

			console.log($(element).css('padding'))

			$(element).height(parseFloat(parseFloat($(element).outerWidth() - curPaddingX) / nBlockRatio))

		})

	}*/

})(jQuery)